<script
    lang="ts"
    setup
>
    import type { ChatMessage, ChatMessageConfig } from '~/ts/types/chat'

    type Props = {
        message: ChatMessage
        messageConfig: ChatMessageConfig
    }

    const props = defineProps<Props>()

    const messageTime = computed<string>(() => dateUtil.fromSeconds(props.message.created_at).toFormat('HH:mm'))
</script>

<template>
    <div class="flex flex-row-reverse items-center gap-1 pb-2.5">
        <div
            key="time"
            class="text-[14px] text-[#8a8f9e] leading-[130%]"
        >
            <template v-if="props.messageConfig.isNote">
                {{ $t('note') }}
            </template>

            {{ messageTime }}
        </div>

        <template v-if="props.messageConfig.out">
            <LazyAppIconAlertCircleOutline
                v-if="props.messageConfig.sendingError"
                key="error"
                size="16"
                color="#dd0e0e"
            />

            <LazyAppIconClockOutline
                v-else-if="props.messageConfig.sending"
                key="sending"
                size="16"
            />

            <template v-else-if="!props.messageConfig.isNote">
                <LazyAppIconCheckAll
                    v-if="props.messageConfig.viewed"
                    key="viewed"
                    size="16"
                />

                <LazyAppIconCheck
                    v-else-if="props.messageConfig.sent"
                    key="sent"
                    size="16"
                />
            </template>
        </template>
    </div>
</template>
