<script
    lang="ts"
    setup
>
    type Props = {
        url: string
    }

    const props = defineProps<Props>()

    const { t } = useLang()

    const href = computed<string>(() => stringUtil.ensureProtocol(props.url))

    const openConfirm = async () => {
        if (!await useConfirm({
            titleText: t('chat-link-confirm-1'),
            bodyText: t('chat-link-confirm-2') + ` ${ props.url }?`,
            confirmText: t('chat-link-confirm-3'),
        })) {
            return
        }

        window.open(href.value, '_blank')
    }
</script>

<template>
    <AppLink
        external
        target="_blank"
        inactive-color="#0D54C9"
        class="!inline !leading-[inherit]"
        :title="href"
        :to="href"
        @click.prevent="openConfirm"
    >
        {{ props.url }}
    </AppLink>
</template>
