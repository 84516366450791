<script
    lang="ts"
    setup
>
    import type { AnyFn } from '~/ts/types/common'
    import type { QuickAnswer, QuickAnswerTag } from '~/ts/types/quick-answer'
    import { useChatStore } from '~/stores/chat'
    import { useUserStore } from '~/stores/user'

    type Props = {
        inputModel: string
    }

    type Emit = {
        (event: 'select-quick-answer', value: string): void
        (event: 'closed'): void
    }

    const props = defineProps<Props>()
    const emit = defineEmits<Emit>()

    const dropdownRef = ref<ReturnType<typeof defineComponent>>()
    const tagListRef = ref<HTMLDivElement>()
    const answerListRef = ref<HTMLDivElement>()

    const chatStore = useChatStore()
    const userStore = useUserStore()

    const { maxTablet, tablet } = useWindowSize()

    const quickAnswersWithoutTags = computed<QuickAnswer[]>(() => {
        return chatStore.quickAnswers.filter((quickAnswer: QuickAnswer) => !quickAnswer.tags.length)
    })

    const answerTags = computed<QuickAnswerTag[]>(() => (
        quickAnswersWithoutTags.value.length
            ? [
                ...chatStore.quickAnswerTags,
                { id: 0, name: '' },
            ]
            : chatStore.quickAnswerTags
    ))

    const currentMenu = ref<'tag' | 'answer'>('tag')

    const currentTag = ref<QuickAnswerTag>()

    const currentTagIndex = computed<number>(() => {
        return answerTags.value.findIndex(tag => tag.id === currentTag.value.id)
    })

    const filteredQuickAnswers = computed<QuickAnswer[]>(() => {
        if (!currentTag.value) {
            return []
        }

        if (currentTag.value.id === 0) {
            return quickAnswersWithoutTags.value
        }

        return chatStore.quickAnswers.filter((quickAnswer: QuickAnswer) => {
            return quickAnswer.tags.length
                && quickAnswer.tags.findIndex(tag => (tag.id === currentTag.value.id)) !== -1
        })
    })

    const currentAnswer = ref<QuickAnswer>()

    const currentAnswerIndex = computed<number>(() => {
        return filteredQuickAnswers.value.findIndex(answer => answer.id === currentAnswer.value.id)
    })

    const answerToAction = ref<QuickAnswer | { new: boolean }>()

    const navigateToMenu = (menu: 'tag' | 'answer'): void => {
        if (currentMenu.value === menu) {
            return
        }

        currentMenu.value = menu

        if (menu === 'tag') {
            currentAnswer.value = null
        } else {
            currentAnswer.value = filteredQuickAnswers.value[0]
        }
    }

    const selectAnswer = (answer: QuickAnswer, close: AnyFn): void => {
        emit('select-quick-answer', answer.content)

        close()
    }

    const navigateToItem = (position: 'current' | 'next' | 'previous'): void => {
        if (position === 'current') {
            if (currentMenu.value === 'tag') {
                navigateToMenu('answer')
            } else {
                selectAnswer(currentAnswer.value, dropdownRef.value.close)
            }

            return
        }

        let index = currentMenu.value === 'tag'
            ? currentTagIndex.value
            : currentAnswerIndex.value

        if (position === 'next') {
            index++
        } else {
            index--
        }

        const items = currentMenu.value === 'tag'
            ? answerTags
            : filteredQuickAnswers

        const currentItem = currentMenu.value === 'tag'
            ? currentTag
            : currentAnswer

        const itemList = currentMenu.value === 'tag'
            ? tagListRef
            : answerListRef

        if (!items.value[index]) {
            return
        }

        currentItem.value = items.value[index]

        nextTick(() => {
            const itemEl = itemList.value.querySelector(`[data-id="${ currentItem.value.id }"]`)

            itemEl.scrollIntoView({
                block: 'nearest',
            })
        })
    }

    const onKeydownHandler = (event): void => {
        if (answerToAction.value) {
            return
        }

        const run = (callback) => {
            event.preventDefault()

            callback()
        }

        switch (event.code) {
            case 'ArrowUp':
                return run(() => navigateToItem('previous'))
            case 'ArrowDown':
                return run(() => navigateToItem('next'))
            case 'ArrowLeft':
                return run(() => navigateToMenu('tag'))
            case 'ArrowRight':
                return run(() => navigateToMenu('answer'))
            case 'Enter':
                return run(() => navigateToItem('current'))
            case 'Backspace':
                return run(() => navigateToMenu('tag'))
        }
    }

    const onKeydownSlashHandler = (event): void => {
        if (
            props.inputModel
            || event.shiftKey
            || event.metaKey
        ) {
            return
        }

        if (event.code === 'Slash') {
            event.preventDefault()

            dropdownRef.value.toggle()
        }
    }

    const activateListeners = (): void => {
        window.addEventListener('keydown', onKeydownHandler, { passive: false })
    }

    const deactivateListeners = (): void => {
        window.removeEventListener('keydown', onKeydownHandler)
    }

    const fillQuickAnswers = async (refresh?: boolean): Promise<void> => {
        try {
            await chatStore.fillQuickAnswers(refresh)
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
        } catch (error) {
            /* empty */
        }
    }

    onMounted(() => nextTick(() => {
        fillQuickAnswers()
            .then(async () => {
                await nextTick()

                if (tablet.value) {
                    currentTag.value = answerTags.value[0]
                }
            })
    }))

    onUnmounted(() => deactivateListeners())

    const { window } = getClientContext()

    onEvent(window, 'keydown', onKeydownSlashHandler, { passive: false })
</script>

<template>
    <LazySettingQuickAnswerPageCreateOrEdit
        v-if="answerToAction"
        :answer-id="answerToAction.id"
        @submitted="fillQuickAnswers(true)"
        @close="answerToAction = null; emit('closed')"
    />

    <AppDropdown
        ref="dropdownRef"
        from-bottom
        content-class="
            max-h-[50dvh]
            min-w-[100vw]
            tablet:min-w-[520px]
            desktop:min-w-[624px]
            max-tablet:bottom-full
            max-tablet:border-b
            max-tablet:border-b-[#f6f7f8]
            max-tablet:rounded-[16px_16px_0_0]
            max-tablet:shadow-[0_-16px_30px_0_rgba(46,56,66,0.05)]
        "
        class="max-tablet:static"
        without-padding
        :disable-closing="!!answerToAction"
        @opened="activateListeners()"
        @closed="deactivateListeners(); emit('closed')"
    >
        <template #activator="{ toggle: toggleDropDown, active }">
            <AppTooltipProRestriction
                :tooltip-args="{
                    offsetX: 110,
                    tailOffsetX: 110,
                    disabled: !!userStore.activeSubscription,
                }"
            >
                <template #activator="{ open: openTooltip, close: closeTooltip }">
                    <AppButtonIcon
                        v-slot="{ color }"
                        small
                        :active="active"
                        :class="[ '!mr-2', { '!cursor-not-allowed': !userStore.activeSubscription } ]"
                        @mouseenter.passive="openTooltip"
                        @mouseleave.passive="closeTooltip"
                        @click="userStore.activeSubscription ? toggleDropDown() : openTooltip()"
                    >
                        <AppIconMessageProcessingOutline
                            v-if="userStore.activeSubscription"
                            key="active"
                            size="20"
                            :color="color"
                        />

                        <AppIconMessageLocked
                            v-else
                            key="locked"
                            size="20"
                            :color="color"
                        />
                    </AppButtonIcon>
                </template>
            </AppTooltipProRestriction>
        </template>

        <template #default="{ close }">
            <div class="flex flex-col chat-conversation-footer-input-controls-h">
                <div
                    v-if="chatStore.quickAnswers.length"
                    key="filled"
                    class="
                        overflow-hidden
                        flex
                        gap-2
                        h-full
                        p-[16px_16px_0]
                        max-tablet:pr-0
                    "
                >
                    <div
                        v-show="tablet || !currentTag"
                        class="w-full tablet:w-[140px]"
                    >
                        <div class="flex items-center justify-between gap-2">
                            <AppButton
                                underlined
                                class="!p-2"
                                @click="close(); (answerToAction = { new: true })"
                            >
                                {{ $t('add-answer') }}
                            </AppButton>

                            <AppButtonIcon
                                class="!flex tablet:!hidden mr-2"
                                small
                                @click="close()"
                            >
                                <AppIconClose size="20" />
                            </AppButtonIcon>
                        </div>

                        <div
                            ref="tagListRef"
                            class="
                                overflow-y-auto
                                flex
                                flex-col
                                gap-1
                                w-full
                                h-[calc(100%-56px)]
                                mt-4
                                pb-2
                                max-tablet:pr-4
                            "
                        >
                            <AppDropdownItem
                                v-for="tag in answerTags"
                                :key="tag.name"
                                :active="currentTag?.id === tag.id"
                                inactive-color="#8a8f9e"
                                :data-id="tag.id"
                                class="!font-medium"
                                :title="tag.name"
                                @mouseenter.passive="tablet && (currentTag = tag)"
                                @click="maxTablet && (currentTag = tag)"
                            >
                                <div class="truncate">
                                    /{{ tag.name }}
                                </div>
                            </AppDropdownItem>
                        </div>
                    </div>

                    <div
                        v-show="currentTag"
                        ref="answerListRef"
                        class="overflow-y-auto flex flex-col gap-1 w-full pb-2 max-tablet:pr-4"
                    >
                        <AppButtonIcon
                            v-show="maxTablet"
                            small
                            class="mb-1"
                            @click="currentTag = undefined"
                        >
                            <AppIconArrowLeft size="20" />
                        </AppButtonIcon>

                        <AppDropdownItem
                            v-for="answer in filteredQuickAnswers"
                            :key="answer.id"
                            :active="currentAnswer?.id === answer.id"
                            inactive-color="#8a8f9e"
                            :data-id="answer.id"
                            class="group items-end justify-between"
                            :title="answer.content"
                            @click="selectAnswer(answer, close)"
                        >
                            <div class="true-word-breaks overflow-hidden line-clamp-4 max-h-[74px]">
                                {{ answer.content }}
                            </div>

                            <AppButtonIcon
                                extra-small
                                class="opacity-0 group-hover:opacity-100 !w-5 !h-5 ml-1 p-1"
                                @click.stop="answerToAction = answer"
                            >
                                <AppIconPencil size="16" />
                            </AppButtonIcon>
                        </AppDropdownItem>
                    </div>
                </div>

                <div
                    v-else
                    key="empty"
                    class="overflow-hidden flex flex-col items-center justify-center h-full p-[16px_16px_0]"
                >
                    <div class="flex items-center mb-4">
                        <!-- eslint-disable vue/no-v-html -->
                        <div
                            class="mr-2 text-[16px]"
                            v-html="$t('chat-quick-answers-empty-state-text')"
                        ></div>
                        <!-- eslint-enable vue/no-v-html -->

                        <AppChip
                            active
                            clearable
                            @clear="() => null"
                        >
                            {{ $t('tag') }}
                        </AppChip>
                    </div>

                    <AppLink
                        wrapper
                        :to="{ name: 'p-pid-setting-quick-answer' }"
                    >
                        <AppButton>
                            {{ $t('go-to-quick-answers') }}
                        </AppButton>
                    </AppLink>
                </div>

                <ChatConversationFooterInputControlsModalFooter>
                    <template #after-cancel>
                        <div class="m-[0_8px_0_24px] font-medium">
                            /
                        </div>

                        <div>
                            {{ $t('open') }}
                        </div>
                    </template>
                </ChatConversationFooterInputControlsModalFooter>
            </div>
        </template>
    </AppDropdown>
</template>
